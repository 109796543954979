html,
body {
    position: relative;

    padding: 0;
    margin: 0;

    background-color: $gray-background;

    width: 100%;
    height: 100%;

    font-family: 'Barlow', Helvetica, Arial, sans-serif;
    font-size: $base-font-size;

    color: $text-color;

    @media only screen and (max-width: 1024px) {
        font-size: 14px;
    }
}

#root {
    height: 100%;
}

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: $primary;

    &:visited {
        color: $primary;
    }
}

button,
input {
    font-family: 'Barlow', Helvetica, Arial, sans-serif;
}

button {
    &.linkButton {
        border: none;
        color: $primary;
        background-color: transparent;
        outline: none;
        cursor: pointer;
    }
}

.main-container {
    position: relative;
    display: flex;
    height: 100%;
}

main {
    position: relative;
    padding: 25px;
    top: $headerHeightInPx;
    background-color: $gray-background;
    -webkit-flex: 9;
    flex: 9;
    height: calc(100% - $headerHeightInPx);
    overflow-y: auto;

    &.full-width {
        padding: 0 0 80px;
    }
}

.hidden {
    display: none;
}

 .no-scroll {
    overflow: hidden;
}

.font-16 {
    font-size: getREM(16);
}

.isBold,
.text-bold {
    font-weight: 600;
}

.percentage {
    &:after {
        content: '%';
    }
}

.text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.text-uppercase {
    text-transform: uppercase;
}

.flex-horizontal-center {
    justify-content: center;
}

.flex-vertical-center {
    align-items: center;
}

.flex-center {
    @extend .flex-horizontal-center, .flex-vertical-center;
}

.scroll-disabled {
    overflow: hidden;
}

.preloadLoadingCircle {
    display: none;
}

.dot {
    display: flex;
    position: relative;
    overflow: hidden;
    justify-content: flex-end;
    border-radius: 50%;

    &--small {
        width: 7px;
        height: 7px;
        background-color: $blue-gray--light;
    }

    &--outline {
        $dot__outline--size: 14px;
        $dot__outline--border: 2px;

        width: $dot__outline--size;
        height: $dot__outline--size;
        border: $dot__outline--border solid $blue-gray--light;
        background-color: transparent;
        border-color: $text-color;

        &.thisTumorType {
            background-color: $text-color;
        }

        &.otherTumorTypes {
            &:after {
                // Width of "dot" minus the width of border on both sides (left and right) divided by two
                // because it is half fill
                $half-fill-width: calc(
                    ($dot__outline--size - $dot__outline--border * 2) / 2
                );

                position: absolute;
                top: 0;
                right: 0;
                width: $half-fill-width;
                height: $dot__outline--size;
                background-color: $text-color;
                content: ' ';
            }
        }
    }
}
