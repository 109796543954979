@import "variables.scss";
@import "common.scss";

.accessPage {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-flow: row nowrap;
  background-color: #fff;

  &__formContainer {
    position: relative;
    display: inline-flex;
    flex: 2;
    flex-direction: column;
    justify-content: center;
    overflow-y: auto;
    padding: 3%;

    @media only screen and (min-width: 768px) {
      padding: 0 10vw;
      max-width: 100%;
    }
  }

  &__imageContainer {
    display: inline-flex;
    flex: 5;
    background: url("../assets/images/abstract.webp") center/cover;

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.loginPage {
  &__googleButton {
    margin-bottom: 3vh;
  }

  &__contentWrapper {
    &.animateIn {
      animation: fadeIn 0.5s;
    }
  }

  &__form {
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    gap: 1vh;

    &__input {
      display: block;
      width: 100%;
      margin-bottom: 2.5vh;

      &:nth-last-child(2) {
        margin-bottom: 4vh;
      }

      label {
        font-size: 2vh;
      }

      input {
        font-size: 2vh;
      }
    }
  }

  &__footer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-top: 3vh;
    text-align: center;
    padding-bottom: 3vh;

    &__textWithLink {
      display: block;
      color: $text-color;
      font-size: 1.8vh;
      margin-bottom: 3.5vh;
      text-align: center;

      a {
        text-decoration: underline;
        font-weight: 700;
        font-size: 1.8vh;
      }
    }
  }
}

.page {
  &__header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__logo {
      max-height: 27vh;
      margin-bottom: 3vh;
    }

    &__title {
      color: $primary;
      font-weight: 700;
      font-size: 2.6vh;
      margin-bottom: 3vh;
    }

    &__subtitle {
      color: $gray--dark;
      font-size: 1.8vh;
      margin-bottom: 3vh;
      padding: 0 3vh;
    }

    &__link {
      display: block;
      color: $text-color;
      font-size: 1.8vh;
      margin-bottom: 3.5vh;

      a {
        text-decoration: underline;
        font-weight: 700;
      }
    }
  }

  &__text {
    color: $primary;
    font-size: 1.8vh;
    margin-bottom: 1vh;
    padding: 0 3vh;
    text-align: center;
  }
}

.copyrightLabel {
  display: block;
  text-align: center;
  color: $gray--dark;
  font-size: 1.4vh;
  margin-top: 5vh;

  a {
    text-decoration: underline;
    color: $gray--dark !important;

    &:visited {
      color: $gray--dark;
    }
  }
}

#root {
  width: 100%;
  height: 100%;
}

.server-selector-btn {
  font-weight: 600;
  font-size: 2vh;
  height: 5vh;
  background-color: $primary;
  color: $white;
  width: 100%;

  @media only screen and (max-width: 1024px) {
    font-size: 1.5vh;
  }

  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: Roboto, Barlow, Helvetica, Arial, sans-serif;
  line-height: 1.0625rem;
  border-radius: 0.375rem;
  text-transform: uppercase;

  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;

  :hover {
    cursor: pointer;
  }
}
