html, body {
  color: #231f20;
  background-color: #f5f7f8;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Barlow, Helvetica, Arial, sans-serif;
  font-size: 16px;
  position: relative;
}

@media only screen and (max-width: 1024px) {
  html, body {
    font-size: 14px;
  }
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

a {
  color: #09293e;
  text-decoration: none;
}

a:visited {
  color: #09293e;
}

button, input {
  font-family: Barlow, Helvetica, Arial, sans-serif;
}

button.linkButton {
  color: #09293e;
  cursor: pointer;
  background-color: #0000;
  border: none;
  outline: none;
}

.main-container {
  height: 100%;
  display: flex;
  position: relative;
}

main {
  background-color: #f5f7f8;
  flex: 9;
  height: calc(100% - 60px);
  padding: 25px;
  position: relative;
  top: 60px;
  overflow-y: auto;
}

main.full-width {
  padding: 0 0 80px;
}

.hidden {
  display: none;
}

.no-scroll {
  overflow: hidden;
}

.font-16 {
  font-size: getREM(16);
}

.isBold, .text-bold {
  font-weight: 600;
}

.percentage:after {
  content: "%";
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-uppercase {
  text-transform: uppercase;
}

.flex-horizontal-center, .flex-center {
  justify-content: center;
}

.flex-vertical-center, .flex-center {
  align-items: center;
}

.scroll-disabled {
  overflow: hidden;
}

.preloadLoadingCircle {
  display: none;
}

.dot {
  border-radius: 50%;
  justify-content: flex-end;
  display: flex;
  position: relative;
  overflow: hidden;
}

.dot--small {
  background-color: #ebeff0;
  width: 7px;
  height: 7px;
}

.dot--outline {
  background-color: #0000;
  border: 2px solid #231f20;
  width: 14px;
  height: 14px;
}

.dot--outline.thisTumorType {
  background-color: #231f20;
}

.dot--outline.otherTumorTypes:after {
  content: " ";
  background-color: #231f20;
  width: 5px;
  height: 14px;
  position: absolute;
  top: 0;
  right: 0;
}

.accessPage {
  background-color: #fff;
  flex-flow: row;
  display: flex;
  position: absolute;
  inset: 0;
}

.accessPage__formContainer {
  flex-direction: column;
  flex: 2;
  justify-content: center;
  padding: 3%;
  display: inline-flex;
  position: relative;
  overflow-y: auto;
}

@media only screen and (min-width: 768px) {
  .accessPage__formContainer {
    max-width: 100%;
    padding: 0 10vw;
  }
}

.accessPage__imageContainer {
  background: url("abstract.f4143a52.webp") center / cover;
  flex: 5;
  display: inline-flex;
}

@media only screen and (max-width: 768px) {
  .accessPage__imageContainer {
    display: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.loginPage__googleButton {
  margin-bottom: 3vh;
}

.loginPage__contentWrapper.animateIn {
  animation: .5s fadeIn;
}

.loginPage__form {
  flex-direction: column;
  gap: 1vh;
  margin-top: 5vh;
  display: flex;
}

.loginPage__form__input {
  width: 100%;
  margin-bottom: 2.5vh;
  display: block;
}

.loginPage__form__input:nth-last-child(2) {
  margin-bottom: 4vh;
}

.loginPage__form__input label, .loginPage__form__input input {
  font-size: 2vh;
}

.loginPage__footer {
  text-align: center;
  flex-direction: column;
  align-content: center;
  margin-top: 3vh;
  padding-bottom: 3vh;
  display: flex;
  position: relative;
}

.loginPage__footer__textWithLink {
  color: #231f20;
  text-align: center;
  margin-bottom: 3.5vh;
  font-size: 1.8vh;
  display: block;
}

.loginPage__footer__textWithLink a {
  font-size: 1.8vh;
  font-weight: 700;
  text-decoration: underline;
}

.page__header {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.page__header__logo {
  max-height: 27vh;
  margin-bottom: 3vh;
}

.page__header__title {
  color: #09293e;
  margin-bottom: 3vh;
  font-size: 2.6vh;
  font-weight: 700;
}

.page__header__subtitle {
  color: #7f7f7f;
  margin-bottom: 3vh;
  padding: 0 3vh;
  font-size: 1.8vh;
}

.page__header__link {
  color: #231f20;
  margin-bottom: 3.5vh;
  font-size: 1.8vh;
  display: block;
}

.page__header__link a {
  font-weight: 700;
  text-decoration: underline;
}

.page__text {
  color: #09293e;
  text-align: center;
  margin-bottom: 1vh;
  padding: 0 3vh;
  font-size: 1.8vh;
}

.copyrightLabel {
  text-align: center;
  color: #7f7f7f;
  margin-top: 5vh;
  font-size: 1.4vh;
  display: block;
}

.copyrightLabel a {
  text-decoration: underline;
  color: #7f7f7f !important;
}

.copyrightLabel a:visited {
  color: #7f7f7f;
}

#root {
  width: 100%;
  height: 100%;
}

.server-selector-btn {
  color: #fff;
  box-sizing: border-box;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  background-color: #09293e;
  border: 0;
  border-radius: .375rem;
  outline: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 64px;
  height: 5vh;
  margin: 0;
  padding: 0;
  font-family: Roboto, Barlow, Helvetica, Arial, sans-serif;
  font-size: 2vh;
  font-weight: 600;
  line-height: 1.0625rem;
  text-decoration: none;
  transition: background-color .25s cubic-bezier(.4, 0, .2, 1), box-shadow .25s cubic-bezier(.4, 0, .2, 1), border .25s cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
  position: relative;
}

@media only screen and (max-width: 1024px) {
  .server-selector-btn {
    font-size: 1.5vh;
  }
}

.server-selector-btn :hover {
  cursor: pointer;
}

/*# sourceMappingURL=index.a32266b2.css.map */
