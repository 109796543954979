//
// Size declarations
//
$base-font-size: 16px;

$headerHeight: 60;
$patientPageHeaderHeight: 80;
$headerHeightInPx: #{$headerHeight}px;
$patientPageHeaderHeightInPx: #{$patientPageHeaderHeight}px;

//
// Color declarations
//
$primary: #09293e;
$primary__hover: #2963cf;
$primary__active: #255bc1;

$error: #fa0040;

$gray-background--filled: rgb(240, 243, 243);
$gray-background: rgb(245, 247, 248);
$gray-background--opaque: rgba(245, 247, 248, 0.9);
$gray-background--dark--opaque: rgba(33, 33, 33, 0.9);
$gray-shadow--dark--opaque: rgba(35, 31, 32, 0.09);

$gray-background--light--opaque: rgba(235, 239, 240, 0.12);
$gray-background--medium--opaque: rgba(235, 239, 240, 0.36);

$gray-background--light: #fcfdfd;
$gray-background--medium: #f7f9f9;

$blue-background--opaque: rgba(9, 41, 62, 0.8);

$white: #ffffff;
$gray: #9b9b9b;
$gray--medium: #c5c5c5;
$gray--light--pulsate: #e6e6e6;
$gray--light: #dddddd;
$gray--lighter: #eaeaea;
$gray--lightest: #f9f9f9;
$gray--dark: #7f7f7f;
$text-color: #231f20;
$blue-gray--light: #ebeff0;
$gray--light__hover: #fafafa;
$gray--light__active: #f5f5f5;
$green--light: #04d49d;
$orange: #e67e22;
$oxford-blue: #09293e;
$han-purple: #5034f7;
$chartreuse-green: #c5fa36;
$seashell-yellow: #faf7f0;
$platinum-grey: #e3e8e8;

$compound__thisTumorType: #2ecc71;
$compound__thisTumorType--disabled: #b7e8cb;
$compound__otherTumorTypes: #e67e22;
$compound__otherTumorTypes--disabled: #eecfb6;
$compound__development: #9b59b6;
$compound__development--disabled: #d6c5e1;

$password-strength__bad: #fa0040;
$password-strength__weak: #ec416c;
$password-strength__medium: #ff8200;
$password-strength__good: #2ecc71;
$password-strength__strong: #0e9747;

$genomate-score--details--lightblue: #7fa1ff;
$genomate-score--details--lightred: #ff6188;
$genomate-score--details--aqua: #4bd5f5;
$genomate-score--details--pink: #f894e1;
$genomate-score--details--red: #ec416c;
